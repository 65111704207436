.home {
  .home-profile {
    @include transform(translateY(14vh));
    padding: 0 0 .5rem;
    text-align: center;

	

    .home-avatar {
      padding: .5rem;

      img {
        display: inline-block;
        width: 8rem;
        height: auto;
        margin: 0 auto;
        @include border-radius(100%);
        @include box-shadow(0 0 0 .3618em rgba(0, 0, 0, .05));
        @include transition(all 0.4s ease);

        &:hover {
          position: relative;
          @include transform(translateY(-.75rem));
        }
      }
    }

    .home-title {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
	  padding-top: 1rem;

    }

    .home-subtitle {
      font-size: 1rem;
      font-weight: normal;
      margin-bottom: 100px;
      padding: 1rem;
	  padding-bottom: 1rem;
    }
	

	

    .links {
      padding: .5rem;
      font-size: 1.5rem;

      a * {
        vertical-align: text-bottom;
      }

      img {
        height: 1.5rem;
        padding: 0 .25rem;
      }
    }

    .home-disclaimer {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: normal;
      margin: 0;
      padding: .5rem;
      color: $global-font-secondary-color;

      [theme=dark] & {
        color: $global-font-secondary-color-dark;
      }
    }
  }
}

.home[data-home=posts] {
  .home-profile {
    @include transform(translateY(0));
    padding-top: 2rem;
  }

  .home-avatar img {
    width: 6rem;
  }


  .summary {
    padding-top: 1rem;
    padding-bottom: .8rem;
    color: $global-font-color;
/*  border-bottom: 5px dashed $global-border-color;*/

    [theme=dark] & {
      color: $global-font-color-dark;
/*      border-bottom: 5px dashed $global-border-color-dark;*/
    }


    .featured-image-preview {
      width: 100%;
      padding: 30% 0 0;
      position: relative;
      margin: 0.6rem auto;
      @include transition(transform 0.4s ease);

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        &.lazyloaded {
          @include object-fit(cover);
        }
      }

      &:hover {
        @include transform(scale(1.01));
      }
    }

    .single-title {
      font-size: 1.25rem;
      line-height: 140%;
      margin: 0.4rem 0;


    }

    .content {
      @include box(vertical);
      -webkit-line-clamp: 3;
      margin-top: 0.3rem;

      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      @include overflow-wrap(break-word);
      color: $global-font-secondary-color;

      [theme=dark] & {
        color: $global-font-secondary-color-dark;
      }

      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1rem;
        line-height: 1.5;
        display: inline;



        &::after {
          content: "\A";
          white-space: pre;
        }
      }

      h2 {
        font-size: 1.125rem;


      }

      @include link(false, true);

      b, strong {
        color: $global-font-secondary-color;

        [theme=dark] & {
          color: $global-font-secondary-color-dark;
        }
      }
    }

    .post-footer {
      margin-top: 9.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 4.875rem;

      @include link(false, false);

      .post-tags {
        padding: 0;

        @include link(true, true);
      }
    }
  }
}
