.page {
  position: relative;
  max-width: 1500px;
  width: 70%;
  margin: 0 auto;



  [data-header-desktop] & {
    padding-top: 0;

  }

  [data-header-desktop=normal] & {
    padding-top: 0;

  }
  


  @include blur;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";
