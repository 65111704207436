footer {
  height: 2rem;
  width: 100%;
  text-align: center;
  line-height: 1.25rem;
  padding: 1.25rem 0;
  border-top-style: dotted;
  margin: auto;
  width: 85%;
  position: relative;

  .footer-container {
    font-size: 1.5rem;
	margin-right: 0;



    .footer-line {
      width: 100%;


      .icp-br {
        display: none;
      }
    }
  }

  @include blur;
}
