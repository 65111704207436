.archive {
  .single-title {
    text-align: right;
	font-size: 1.6rem;
	margin: 6rem 0 2.5rem;
  }

  .group-title {
    margin-top: .5rem;
    margin-bottom: 1rem;

  }

  @import "../_partial/_archive/terms";
  @import "../_partial/_archive/tags";
}
